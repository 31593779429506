import { CSSProperties, FunctionComponent, useMemo } from "react";

import { faCirclePlay } from "@fortawesome/pro-duotone-svg-icons/faCirclePlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";

import { useTeamTheme } from "theme/context/base";
import { defaultTheme } from "theme/mui-theme";
import { Link } from "ui/src/Link";
import { formatDateString, getPostSlug } from "utils/helpers/contentUtils";
import { populateSizes, SizePoints } from "utils/helpers/sizeUtils";
import { useBreakpoint } from "utils/hooks/useBreakpoint";

import BadgeRow from "./BadgeRow";

import type { VideoPost } from "utils/types/content";
import type { CardVariant } from "utils/types/image-card";

export const stylesConfig = {
  // Corresponds to List View in the Figma Design
  small: {
    image: {
      width: 228,
      height: 160,
      format: "small",
      sx: {
        borderRadius: "0px 22px 22px 0px",
      },
    },
    imageContainer: {
      sx: {
        marginRight: "1rem",
        minHeight: { xs: "max-content" },
        minWidth: {
          xs: "max-content",
          xl: "clamp(13.7rem,calc(13.7rem + 95vw - 80rem) ,16rem)",
          xxl: "max-content",
        },
        width: { md: "13.5rem" },
      },
    },
    container: { sx: { flexDirection: "row" } },
    textContainer: { sx: { padding: "0.375rem" } },
    title: {
      sx: { fontSize: { xs: "1rem" }, fontWeight: 600 },
      variant: "body2",
    },
    badges: { sx: { fontSize: "0.781rem" } },
    author: {
      sx: {
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
      },
    },
  },

  // Corresponds to Grid View in the Figma Design
  medium: {
    image: {
      width: 385,
      height: 190,
      format: "medium",
      sx: { width: "100%", height: "100%", borderRadius: "17px" },
    },
    imageContainer: {
      sx: { width: "auto" },
    },
    container: {
      sx: { flexDirection: "column", height: "99%", borderRadius: "17px" },
    },
    textContainer: { sx: { padding: "1rem", flex: 1 } },
    title: {
      sx: { fontSize: { xs: "1.125rem" }, fontWeight: 600 },
      variant: "body1",
    },
    badges: { sx: { fontSize: "0.781rem" } },
    author: {
      sx: {
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: { xs: "start" },
        alignItems: { xs: "start", sm: "center" },
      },
    },
    separator: {
      sx: {
        display: { xs: "none", sm: "block" },
      },
    },
  },
};

interface Props {
  post: VideoPost;
  variant?: CardVariant;
  sizePoints?: SizePoints;
  className?: string;
}

const VideoPostCard: FunctionComponent<Props> = ({
  post,
  variant,
  sizePoints,
  className,
}) => {
  const { query } = useRouter();

  const theme = useTheme();
  const teamColor =
    useTeamTheme(post.team.team.toLowerCase(), theme.palette.mode)
      ?.buttonBackground?.hex || defaultTheme.primaryColor;

  const yt = useMemo(() => JSON.parse(post.youTube), [post.youTube]);

  const populatedSizePoints = populateSizes(sizePoints);
  const breakpoint = useBreakpoint();

  const currentSize = useMemo(
    () => variant || populatedSizePoints[breakpoint] || "medium",
    [variant, breakpoint]
  );

  return (
    <>
      <Stack
        component={Link}
        shallow
        aria-label="blog post"
        href={`${
          post.team.team === "TPL" ? "" : `/${post.team.team.toLowerCase()}`
        }/content/videos/${getPostSlug(post)}${
          query.team ? "" : "?highlighted=true"
        }`}
        direction="row"
        className={`relative z-20 flex h-full ${className ?? ""}`}
        sx={{
          overflow: "hidden",
          color: "text.primary",
          backgroundColor: "inherit",
          "&:hover, &:focus": {
            borderRadius: "17px",
            "+div": { display: "block" }, // toggle appearance of hover border
          },
          ...stylesConfig[currentSize].container.sx,
        }}
      >
        <Stack
          sx={stylesConfig[currentSize].imageContainer.sx}
          className="relative"
        >
          <Image
            src={yt.thumbnail}
            alt="video thumbnail"
            width={stylesConfig[currentSize].image.width}
            height={stylesConfig[currentSize].image.height}
            style={{
              ...stylesConfig[currentSize].image.sx,
              objectFit: "cover",
              width: "100%",
              height: "100%",
              aspectRatio: 2.11,
            }}
          />
          <Stack className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <FontAwesomeIcon
              icon={faCirclePlay}
              fontSize="2.5rem"
              style={
                {
                  "--fa-primary-color": "#ffffff",
                  "--fa-secondary-color": "#ba0c2f",
                  "--fa-secondary-opacity": "1",
                } as CSSProperties
              }
            />
          </Stack>
        </Stack>

        <Stack
          sx={{
            ...stylesConfig[currentSize].textContainer.sx,
            color: "text.primary",
          }}
          className="flex-auto"
          direction="column"
        >
          <Stack marginBottom={2}>
            <BadgeRow
              color={teamColor}
              team={post?.team?.team}
              premium={post?.premium}
              sport={post?.sport?.sport}
              sx={stylesConfig[currentSize].badges?.sx}
            />
            <Typography
              variant={stylesConfig[currentSize].title.variant}
              className="my-auto line-clamp-2 h-[3em]"
              sx={stylesConfig[currentSize].title.sx}
              data-testid="image-post-title"
            >
              {post.title}
            </Typography>
          </Stack>

          <Stack sx={stylesConfig[currentSize].author.sx} className="mt-auto">
            <Link
              href={`/home/writer/${post?.writer?.id}`}
              className={`${
                post?.writer?.bio && post?.writer?.profilePicture?.url
                  ? ""
                  : "pointer-events-none"
              }`}
            >
              <Typography
                color="grey.400"
                variant="description"
                alignItems="center"
                justifyContent="center"
                className="mr-1.5"
              >
                {`${post.writer.firstname} ${post.writer.lastname}`}
              </Typography>
            </Link>

            {currentSize === "small" ? (
              <></>
            ) : (
              <Box
                height={"0.375rem"}
                width={"0.375rem"}
                sx={{ ...stylesConfig[currentSize].separator.sx, mr: 1 }}
                className="rounded-full"
                bgcolor="grey.400"
              />
            )}

            <Typography color="grey.400" variant="description">
              {formatDateString(post.publishedAt)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* Hover state border for Image Cards. */}
      <Box display="none">
        <Box
          className="absolute inset-[-3px] z-10 rounded-[20px] border-[3px] border-solid"
          borderColor={teamColor}
        />
        <Box
          className="absolute bottom-[calc(-0.75rem)] left-1/2 h-8 w-[98%] -translate-x-1/2 rounded-b-3xl"
          bgcolor={teamColor}
        />
      </Box>
    </>
  );
};

export default VideoPostCard;
