import { useMemo, type FunctionComponent } from "react";

import { Box, Stack, Typography } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";
import qs from "qs";

import { useTeamTheme } from "theme/context/base";
import { defaultTheme } from "theme/mui-theme";
import { Link } from "ui/src/Link";
import NotificationDot from "ui/src/NavBar/NotificationsMenu/NotificationDot";
import { formatDateString, getPostSlug } from "utils/helpers/contentUtils";
import { populateSizes, SizePoints } from "utils/helpers/sizeUtils";
import { useBreakpoint } from "utils/hooks/useBreakpoint";

import BadgeRow from "./BadgeRow";

import type { ImagePost } from "utils/types/content";
import type { CardVariant } from "utils/types/image-card";

export const stylesConfig = {
  // Corresponds to List View in the Figma Design
  small: {
    image: {
      width: 225,
      height: 126,
      sizes: "(max-width: 640px) 180px, (max-width: 1200px) 200px, 225px",
      format: "small",
      sx: {
        borderRadius: "0px 22px 22px 0px",
        width: "100%",
        height: "auto",
        aspectRatio: "12 / 7",
      },
    },
    imageContainer: {
      sx: {
        marginRight: "1rem",
        minHeight: { xl: "10rem" },
        maxHeight: { xs: "190px", xl: "unset" },
        minWidth: { xs: "8.125rem", sm: "13.5rem" },
        width: { xs: "8.125rem", sm: "13.5rem" },
      },
    },
    container: { sx: { flexDirection: "row" } },
    textContainer: { sx: { padding: "0.375rem" } },
    title: {
      sx: { fontSize: { xs: "1rem" }, fontWeight: 600 },
      variant: "body2",
    },
    badges: { sx: { fontSize: "0.781rem" } },
    author: {
      sx: {
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
      },
    },
  },

  // Corresponds to Grid View in the Figma Design
  medium: {
    image: {
      width: 500,
      height: 190,
      sizes: "(max-width: 640px) 350px, (max-width: 1200px) 400px, 500px",
      sx: { width: "auto", height: "100%" },
      format: "medium",
    },
    imageContainer: {
      sx: { width: "auto", height: "12rem" },
    },
    container: { sx: { flexDirection: "column", height: "99%" } },
    textContainer: { sx: { padding: "1rem", flex: 1 } },
    title: {
      sx: { fontSize: { xs: "1.125rem" }, fontWeight: 600 },
      variant: "body1",
    },
    badges: { sx: { fontSize: "0.781rem" } },
    author: {
      sx: {
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
      },
    },
  },
};

interface Props {
  post: ImagePost;
  variant?: CardVariant;
  sizePoints?: SizePoints;
  className?: string;
  featured?: boolean;
}

const ImagePostCard: FunctionComponent<Props> = ({
  post,
  variant,
  sizePoints,
  className,
  featured,
}) => {
  const populatedSizePoints = populateSizes(sizePoints);
  const breakpoint = useBreakpoint();
  const { query, ...router } = useRouter();

  const isNewsPage = router.pathname.includes("/news");

  const teamColor =
    useTeamTheme(post.team.team.toLowerCase())?.buttonBackground?.hex ||
    defaultTheme.primaryColor;

  const currentSize = useMemo(
    () => variant || populatedSizePoints[breakpoint] || "medium",
    [variant, breakpoint]
  );

  const searchParams = useMemo(
    () =>
      qs.stringify({
        ...(!query.team && !isNewsPage && { highlighted: "true" }),
        ...(isNewsPage && { is_news: "true" }),
      }),
    []
  );

  return (
    <>
      <Stack
        component={Link}
        shallow
        aria-label={post.title}
        href={`${
          post.team.team === "TPL" ? "" : `/${post.team.team.toLowerCase()}`
        }/content/articles/${getPostSlug(post)}?${searchParams}`}
        direction="row"
        className={`relative z-20 flex h-full ${className ?? ""}`}
        sx={{
          overflow: "hidden",
          color: "text.primary",
          backgroundColor: "inherit",
          "&:hover, &:focus": {
            borderRadius: "17px",
            "+div": { display: "block" }, // toggle appearance of hover border
          },
          ...stylesConfig[currentSize].container.sx,
        }}
      >
        <Stack
          sx={stylesConfig[currentSize].imageContainer.sx}
          justifyContent="center"
          position="relative"
        >
          <Image
            priority={featured}
            src={
              post.cover?.formats?.[stylesConfig[currentSize].image.format]
                ?.url ?? post.cover?.url
            }
            alt={post.title}
            width={stylesConfig[currentSize].image.width}
            height={stylesConfig[currentSize].image.height}
            sizes={stylesConfig[currentSize].image.sizes}
            style={{
              ...stylesConfig[currentSize].image.sx,
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </Stack>
        <Stack
          sx={{
            ...stylesConfig[currentSize].textContainer.sx,
            color: "text.primary",
          }}
          className="flex-auto"
          direction="column"
        >
          <Stack marginBottom={2}>
            <BadgeRow
              color={teamColor}
              team={post?.team?.team}
              premium={post?.premium}
              sport={post?.sport?.sport}
              sx={stylesConfig[currentSize].badges?.sx}
            />
            <Typography
              variant={stylesConfig[currentSize].title.variant}
              className="mb-0 line-clamp-2 h-[3em]"
              sx={stylesConfig[currentSize].title.sx}
              data-testid="image-post-title"
            >
              {post.title}
            </Typography>
          </Stack>
          <Stack sx={stylesConfig[currentSize].author.sx} className="mt-auto">
            <Link
              href={`/home/writer/${post?.writer?.id}`}
              className={`${
                post?.writer?.bio && post?.writer?.profilePicture?.url
                  ? ""
                  : "pointer-events-none"
              }`}
            >
              <Typography
                color="grey.400"
                variant="description"
                className="mr-1.5"
              >
                {`${post.writer.firstname} ${post.writer.lastname}`}
              </Typography>
            </Link>
            {currentSize === "small" ? (
              <></>
            ) : (
              <NotificationDot dotColor="grey.400" className="mr-1.5" />
            )}
            <Typography color="grey.400" variant="description">
              {formatDateString(post.publishedAt)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {/* Hover state border for Image Cards. */}
      <Box display="none">
        <Box
          className="absolute inset-[-3px] z-10 rounded-[20px] border-[3px] border-solid"
          borderColor={teamColor}
        />
        <Box
          className="absolute bottom-[calc(-0.75rem)] left-1/2 h-8 w-[98%] -translate-x-1/2 rounded-b-3xl"
          bgcolor={teamColor}
        />
      </Box>
    </>
  );
};

export default ImagePostCard;
