import { FunctionComponent } from "react";

import clsx from "clsx";

import { Button } from "ui/src/Button";

import type { ButtonProps } from "@mui/base/Button";

interface Props extends Omit<ButtonProps, "color"> {
  team: string;
  color: string;
}

const TeamBadge: FunctionComponent<Props> = ({ team, color, className }) => {
  return (
    <Button
      name={team}
      variant="outlined"
      color="primary"
      size="small"
      style={{ color: color, borderColor: color, fontSize: "inherit" }}
      className={clsx(
        className,
        "pointer-events-none block !min-w-[100px] truncate px-1 !text-xs font-semibold uppercase shadow-none"
      )}
    />
  );
};

export default TeamBadge;
