import { useTheme, useMediaQuery, Breakpoint } from "@mui/material";

/**
 * A hook to get the current breakpoint.
 * @returns {Breakpoint} The current breakpoint
 * @example
 * const breakpoint = useBreakpoint();
 * if (breakpoint === "xs") {
 *  return <MobileComponent />;
 * }
 * return <DesktopComponent />;
 */
export const useBreakpoint = (): Breakpoint => {
  const theme = useTheme();

  const breakpoints: Breakpoint[] = ["xs", "sm", "md", "lg", "xl", "xxl"];
  let currentBreakpoint: Breakpoint;

  for (const breakpoint of breakpoints) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (useMediaQuery(theme.breakpoints.up(breakpoint))) {
      currentBreakpoint = breakpoint;
    }
  }

  return currentBreakpoint;
};
