import React, { FunctionComponent, useMemo } from "react";

import { Card, Skeleton, Stack } from "@mui/material";

import { populateSizes, SizePoints } from "utils/helpers/sizeUtils";
import { useBreakpoint } from "utils/hooks/useBreakpoint";

import { stylesConfig as imageStyles } from "../Content/ImageCard";
import { stylesConfig as videoStyles } from "../Content/VideoCard";

const GAP = "30px";
const HALF_GAP = "15px";

interface CardComponentProps {
  sizePoints?: SizePoints;
  type?: "video" | "image";
}

const CardComponent: FunctionComponent<CardComponentProps> = ({
  sizePoints,
  type,
}) => {
  const populatedSizePoints = populateSizes(sizePoints);
  const breakpoint = useBreakpoint();

  const currentSize = useMemo(
    () => populatedSizePoints[breakpoint] || "medium",
    [breakpoint]
  );

  const stylesConfig = useMemo(
    () => (type === "image" ? imageStyles : videoStyles),
    [type]
  );

  return (
    <Stack sx={{ ...stylesConfig[currentSize].container.sx }}>
      <Stack
        sx={stylesConfig[currentSize].imageContainer}
        justifyContent="center"
      >
        <Skeleton
          height={stylesConfig[currentSize].image.height}
          width={stylesConfig[currentSize].image.width}
          sx={stylesConfig[currentSize].image.sx}
          className={`max-w-full ${currentSize === "medium" ? "w-auto" : ""}`}
          variant="rounded"
        />
      </Stack>
      <Stack p={2} sx={stylesConfig[currentSize].textContainer.sx} width="100%">
        <Stack className="flex-row justify-between">
          <Skeleton variant="circular" height={25} width={25} />
          <Skeleton height={25} width={100} variant="rounded" />
        </Stack>
        <Skeleton height={48} width="100%" variant="rounded" sx={{ mt: 2 }} />
        <Skeleton variant="text" height={18} width={92} sx={{ mt: 5 }} />
      </Stack>
    </Stack>
  );
};

interface PostCardProps {
  type: "image" | "video";
  featured?: boolean;
  full?: boolean;
}

export const PostCardSkeleton: FunctionComponent<PostCardProps> = ({
  type,
  featured,
  full,
}) => (
  <Stack
    width={full ? "100%" : { xs: "95%", xl: `calc(50% - ${HALF_GAP})` }}
    flexGrow={0}
    flexShrink={0}
    gap={GAP}
  >
    <Card
      sx={{ bgcolor: { xs: "background.card" } }}
      className="relative size-full rounded-lg shadow-none"
    >
      <CardComponent
        sizePoints={{ xs: "medium", md: featured ? "medium" : "small" }}
        type={type}
      />
    </Card>
  </Stack>
);

export default PostCardSkeleton;
