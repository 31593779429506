import { SvgIcon, SvgIconProps } from "@mui/material";

export const PremiumContentIcon = ({ fill, ...props }: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      width="37"
      height="40"
      viewBox="0 0 37 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1294_2429)">
        <path
          d="M6.59675 6.00753H8.02609C8.24266 6.00753 8.45923 5.9209 8.58917 5.74765C11.0147 3.0189 15.2161 0.463405 21.41 1.67618C26.6942 2.67239 27.8637 6.8738 27.6471 9.7758H30.3325C30.5058 6.05084 27.907 1.45961 21.973 0.333464C14.3065 -1.13919 9.06561 2.54245 6.38018 5.70434C6.33686 5.83428 6.42349 6.00753 6.59675 6.00753Z"
          fill={fill}
        />
        <path
          d="M12.6173 24.5024L12.1841 25.4553C12.1408 25.5853 12.2274 25.7585 12.3574 25.7152C16.9053 25.3254 20.9334 23.4196 24.0087 20.8208H20.9768C18.8977 22.3368 16.2556 23.5495 13.0504 24.1126C12.8771 24.1993 12.7039 24.3292 12.6173 24.5024Z"
          fill={fill}
        />
        <path
          d="M4.08396 9.21266C2.17817 13.2408 3.34764 17.3123 4.38716 18.7416C4.47379 18.9149 4.69035 18.9149 4.86361 18.8283L5.64325 18.3085C5.77319 18.2219 5.8165 18.0486 5.77319 17.9187C4.64704 15.4498 4.56041 12.4179 6.20632 9.21266C6.29295 9.03941 6.46621 8.95278 6.63946 8.95278H10.9275C11.1441 8.95278 11.3606 8.82284 11.4039 8.60627L11.7938 7.78332C11.8371 7.65338 11.7505 7.52344 11.6205 7.52344H0.922081C0.705513 7.52344 0.488946 7.65338 0.445632 7.86995L0.0124974 8.73621C-0.0308161 8.82284 0.0558108 8.90947 0.142438 8.90947H3.95402C4.08396 8.90947 4.17059 9.08272 4.08396 9.21266Z"
          fill={fill}
        />
        <path
          d="M22.7527 38.0594C15.9958 39.662 12.1409 33.3816 7.98278 32.2121C7.85284 32.1688 7.80953 32.0822 7.85284 31.9522L17.9449 7.78332C17.9882 7.65338 17.9016 7.52344 17.7716 7.52344H15.6926C15.476 7.52344 15.3028 7.65338 15.2161 7.82663L5.16741 31.649C5.12409 31.779 4.99415 31.8656 4.86421 31.8656C3.82469 31.9089 3.13167 32.0822 2.82848 32.1688C2.69854 32.2121 2.61191 32.2987 2.5686 32.3854L1.87558 33.8147C1.78895 33.988 1.96221 34.1612 2.13546 34.1179C10.1485 31.6924 12.7473 41.3513 21.973 39.8353C22.1896 39.792 22.3629 39.662 22.5361 39.3588L23.0559 38.4059C23.0992 38.2327 22.9692 38.0161 22.7527 38.0594Z"
          fill={fill}
        />
        <path
          d="M29.8425 11.1143H27.5469V25.9708H29.8425V11.1143Z"
          fill="#EBA900"
        />
        <path
          d="M36.0929 17.3989H21.2363V19.6945H36.0929V17.3989Z"
          fill="#EBA900"
        />
      </g>
      <defs>
        <clipPath id="clip0_1294_2429">
          <rect width="36.0931" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
