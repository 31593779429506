import { LoremIpsum } from "lorem-ipsum";

/**
 * It takes an HTML string, creates a span element, sets the innerHTML of the span to the HTML string,
 * finds all the children of the span, and then adds a newline to the end of each child's textContent
 * or innerText
 * @param htmlString - The HTML string that you want to extract the content from.
 * @returns {string} The content of the HTML string.
 * @example
 * const htmlString = "<p>Hello, <strong>world</strong>!</p>";
 * const content = extractContentFromHTML(htmlString);
 * console.log(content); // "Hello, world!"
 */
export const extractContentFromHTML = (htmlString: string): string => {
  const span = document.createElement("span");
  span.innerHTML = htmlString;
  const children = span.querySelectorAll("*");
  for (let i = 0; i < children.length; i++) {
    if (children[i].textContent) children[i].textContent += "\n";
    else (children[i] as HTMLElement).innerText += "\n";
  }

  return [span.textContent || span.innerText]
    .toString()
    .replace(/ +/g, " ")
    .trim();
};

/**
 * It takes an object with an id, slug, and URL property, and returns a string that is the id and slug,
 * or the id and URL if the URL is not "image-post" or null.
 * @param slug - the post slug automatically created by Strapi
 * @param URL - the post URL created by content creators
 * @returns {string} The post slug
 *
 * @example
 * const post = {
 *  id: "1",
 *  slug: "hello-world",
 *  URL: "image-post"
 * };
 * const postSlug = getPostSlug(post);
 * console.log(postSlug); // "hello-world"
 * @example
 * const post = {
 *  id: "1",
 *  slug: "hello-world-2",
 *  URL: "hello-world"
 * };
 * const postSlug = getPostSlug(post);
 * console.log(postSlug); // "hello-world"
 */
export const getPostSlug = ({
  slug,
  url,
}: {
  slug: string;
  url: string;
}): string => {
  return url && !url?.includes("image-post") && !url?.includes("video-post")
    ? url
    : slug;
};

/**
 * It takes a date string and returns a formatted date string.
 * @param date - The date string to format
 * @returns {string} The formatted relative date string
 * @example
 * const date = "2024-02-20T00:00:00.000Z";
 * const formattedDate = formatDateString(date);
 * console.log(formattedDate); // "2 hours ago"
 */
export const formatDateString = (date: string): string => {
  const postDate = Number(new Date(date));
  const current = Number(new Date());

  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;

  const elapsed = current - postDate;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else {
    const fullDate = new Date(date ?? current)?.toLocaleDateString("en-us", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return fullDate;
  }
};

/**
 * It takes a date string and returns a formatted date string.
 * @param date - The date string to format
 * @returns {string} The formatted date string
 * @example
 * const date = "2024-02-20T00:00:00.000Z";
 * const formattedDate = getDate(date);
 * console.log(formattedDate); // "02-20-2024"
 */
export const getDate = (date: string): string => {
  const dateObject = new Date(date);
  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObject.getFullYear().toString();

  return `${month}-${day}-${year}`;
};

/**
 * Generates 4 paragraphs of random Lorem Ipsum.
 * @returns {string} The generated Lorem Ipsum html string
 * @example
 * const loremIpsum = generateLoremIpsum();
 * console.log(loremIpsum);
 * // <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
 * // <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
 * // <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
 * // <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
 */
export const generateLoremIpsum = () => {
  const lorem = new LoremIpsum(
    {
      sentencesPerParagraph: {
        max: 4,
        min: 1,
      },
      wordsPerSentence: {
        max: 20,
        min: 1,
      },
    },
    "html",
    "<p><br></p>"
  );

  return lorem.generateParagraphs(4);
};
