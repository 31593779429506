import { FunctionComponent } from "react";

import Box from "@mui/material/Box";

import { useTeamTheme } from "theme/context/base";

interface Props {
  /**
   * Color of the dot
   * @type {string}
   * @optional
   * @default "#EBA900" (TPL primary color)
   */
  dotColor?: string;
  /**
   * Radius of the dot
   * @type {string}
   * @optional
   * @default "0.375rem"
   */
  dotRadius?: string;
  /**
   * Additional class name
   * @type {string}
   * @optional
   */
  className?: string;
}

/**
 * Renders a dot to be used as a separator in a notification string.
 */
const NotificationDot: FunctionComponent<Props> = ({
  dotColor,
  dotRadius,
  className,
}) => {
  const tplPrimaryColor = useTeamTheme("tpl").buttonBackground.hex;

  return (
    <Box
      height={dotRadius ?? "0.375rem"}
      width={dotRadius ?? "0.375rem"}
      className={className}
      sx={{
        backgroundColor: dotColor ?? tplPrimaryColor,
        borderRadius: "50%",
      }}
    />
  );
};

export default NotificationDot;
