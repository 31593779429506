import React, { ButtonHTMLAttributes, ReactNode, useMemo } from "react";

import clsx from "clsx";

import { populateSizes, SizePoints } from "utils/helpers/sizeUtils";
import { useBreakpoint } from "utils/hooks/useBreakpoint";

export interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "name"> {
  name?: ReactNode;
  sizePoints?: SizePoints;
  variant?: "contained" | "outlined" | "text";
  size?: "small" | "medium" | "large";
}

const useCurrentSize = (sizePoints: SizePoints) => {
  const breakpoint = useBreakpoint();

  const populatedSizePoints = useMemo(
    () => populateSizes(sizePoints),
    [sizePoints]
  );

  if (!populatedSizePoints) return null;

  return populatedSizePoints[breakpoint];
};

export const Button = React.forwardRef(function Button(
  {
    name,
    size,
    sizePoints,
    children,
    variant,
    className,
    ...props
  }: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const currentSizePoint = useCurrentSize(sizePoints);

  const computedSize = useMemo(
    () => size ?? currentSizePoint ?? "medium",
    [size, sizePoints]
  );

  return (
    <button
      ref={ref}
      className={clsx(
        "relative m-0 box-border inline-flex cursor-pointer select-none appearance-none items-center justify-center rounded-md border-2 border-solid border-button p-0 align-middle font-sans text-base font-semibold uppercase leading-normal tracking-[0.02em] no-underline shadow-none transition-all delay-0 duration-300 ease-[ease]",
        computedSize === "medium" && "h-[55px] min-w-[240px]",
        computedSize === "small" && "h-[35px] min-w-[200px]",
        computedSize === "large" && "h-[65px] min-w-[300px] text-xl",
        variant === "contained" &&
          "bg-button text-button-text hover:bg-button-hover hover:text-button-hover-text",
        variant === "outlined" &&
          "border-button bg-transparent text-default hover:bg-button/10",
        variant === "text" &&
          "border-none bg-transparent text-button hover:text-button/80",
        props.disabled &&
          "pointer-events-none border-none bg-gray-400 text-white dark:bg-gray-400 dark:text-white",
        className
      )}
      {...props}
    >
      {name ?? children}
    </button>
  );
});

Button.defaultProps = {
  variant: "contained",
};
