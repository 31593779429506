import { Breakpoint, ButtonProps } from "@mui/material";

import type { CardVariant } from "../types/image-card";

type Size = CardVariant | ButtonProps["size"];
export type SizePoints = Partial<Record<Breakpoint, Size>>;

/**
 * It takes a partial record of breakpoints and sizes, and returns a complete set of breakpoints
 * and sizes where each breakpoint has a size.
 * @param sizePoints - Partial<Record<Breakpoint, Size>>
 * @returns Record<Breakpoint, Size>
 */
export const populateSizes = (sizePoints: SizePoints) => {
  if (!sizePoints) return;
  const sortedBreakpoints = ["xs", "sm", "md", "lg", "xl", "xxl"];
  const sortedSizePoints = Object.entries(sizePoints)
    .sort(
      ([k1], [k2]) =>
        sortedBreakpoints.indexOf(k1) - sortedBreakpoints.indexOf(k2)
    )
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

  let currentSize = Object.values(sortedSizePoints)[0];
  const populatedSizePoints = {};

  sortedBreakpoints.forEach((p) => {
    if (p in sizePoints) {
      populatedSizePoints[p] = sizePoints[p];
      currentSize = sizePoints[p];
    } else {
      populatedSizePoints[p] = currentSize;
    }
  });

  return populatedSizePoints;
};
