import React, { ReactNode, useEffect } from "react";

import { config } from "@fortawesome/fontawesome-svg-core";
import { Skeleton } from "@mui/material";
import Paper from "@mui/material/Paper";
import dynamic from "next/dynamic";
import NextTopLoader from "nextjs-toploader";

import { useTeamsContext } from "utils/context/TeamsContext";

import type { SiteProps } from "utils/types";
import type { ImagePost, VideoPost } from "utils/types/content";
import type { Team } from "utils/types/teams";

config.autoAddCss = false;

const BreadCrumbs = dynamic(() => import("@/components/common/BreadCrumbs"), {
  ssr: false,
});
const Footer = dynamic(async () => (await import("ui/src/Footer")).Footer, {
  ssr: false,
});
const NavBar = dynamic(async () => (await import("ui/src/NavBar")).NavBar, {
  loading: () => <Skeleton variant="rectangular" height={84} />,
  ssr: false,
});

interface Props extends SiteProps {
  children: ReactNode;
  team?: Team;
  hideFooter?: boolean;
  hideHeader?: boolean;
  post?: ImagePost | VideoPost;
  breadCrumbsProps?: {
    currentPage?: string;
    override?: { [text: string]: string };
  };
  hideBreadCrumbs?: boolean;
  disableThemeToggle?: boolean;
}

const Page = React.forwardRef(function Page(
  {
    children,
    team,
    hideFooter,
    hideHeader,
    breadCrumbsProps,
    hideBreadCrumbs,
    disableThemeToggle,
  }: Props,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { setCurrentTeam } = useTeamsContext();

  useEffect(() => {
    if (team) setCurrentTeam(team);
  }, [team]);

  return (
    <>
      <NextTopLoader showSpinner={false} color="white" />
      <Paper className="flex min-h-screen flex-col" ref={ref}>
        {!hideHeader && (
          <NavBar team={team?.slug} disableThemeToggle={disableThemeToggle} />
        )}
        {!hideBreadCrumbs && <BreadCrumbs {...breadCrumbsProps} team={team} />}
        <main>{children}</main>
        {!hideFooter && <Footer />}
      </Paper>
    </>
  );
});

export default Page;
