import React, { FunctionComponent } from "react";

import { faNewspaper } from "@fortawesome/pro-duotone-svg-icons/faNewspaper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton, Stack, StackProps, useTheme } from "@mui/material";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import { PremiumContentIcon } from "ui/src/Icons/PremiumContentIcon";

import IconMap from "./IconMap";
import TeamBadge from "./TeamBadge";

import type { ImagePost } from "utils/types/content";

const IconParser = dynamic(() => import("fa-icon-parser"), {
  ssr: false,
  loading: () => <Skeleton variant="rounded" width={32} height={32} />,
});

interface BadgeProps extends StackProps {
  team: ImagePost["team"]["team"];
  sport: ImagePost["sport"]["sport"];
  premium: ImagePost["premium"];
  color: string;
}

const BadgeRow: FunctionComponent<BadgeProps> = ({
  team,
  premium,
  sport,
  color,
  ...props
}) => {
  const theme = useTheme();
  const { query } = useRouter();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      marginBottom="0.5rem"
      {...props}
    >
      {IconMap[sport]?.icon_component ? (
        <IconParser jsxString={IconMap[sport].icon_component} color={color} />
      ) : (
        <FontAwesomeIcon icon={faNewspaper} color={color} size="2xl" />
      )}
      <Stack direction="row" overflow="hidden" {...props} height="inherit">
        {premium ? (
          <PremiumContentIcon
            className="mx-2 lg:mx-4"
            fill={theme.palette.mode === "dark" ? "white" : "black"}
            sx={{ fontSize: "2.25em" }}
          />
        ) : null}
        {!query.team && (
          <TeamBadge team={team} color={color} className="!h-[2em] lg:!h-9" />
        )}
      </Stack>
    </Stack>
  );
};

export default BadgeRow;
