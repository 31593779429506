import React, { type PropsWithChildren } from "react";

import clsx from "clsx";
import NextLink, { LinkProps } from "next/link";

interface Props extends PropsWithChildren<LinkProps> {
  underline?: "none" | "hover" | "always";
  className?: string;
}

export const Link = React.forwardRef(function Link(
  { children, underline, className, ...props }: Props,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  return (
    <NextLink
      prefetch={props.prefetch}
      ref={ref}
      className={clsx(
        "text-primary hover:brightness-110 dark:hover:brightness-125",
        underline !== "always" && "no-underline",
        underline === "hover" && "hover:underline",
        underline === "always" && "underline",
        className
      )}
      {...props}
    >
      {children}
    </NextLink>
  );
});

Link.defaultProps = {
  prefetch: false,
  underline: "none",
};
